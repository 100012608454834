//Import Neccessary Modules
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FsLightbox from "fslightbox-react";

//Import CSS
import '../css/Photo_Galleries.css';

export default function All_Photos() {
    const [imageUrls, setImageUrls] = useState([]);
    const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1
	});
    const token = localStorage.getItem("token");

    function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number
		});
	};

    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    useEffect(() => {
        window.scrollTo(0,0);

        //Localhost Link
        // fetch('http://localhost:4000/all_photos', {
        //     method: "GET",
        //         headers: {
        //             "Content-Type": "application/json",
        //             "Authorization": `Bearer ${token}`
        //         }
        //         })
        //         .then((response) => response.json())
        //         .then((data) => {
        //         setImageUrls(data);
        //         })
        //         .catch((error) => console.error('Error fetching pre-signed URLs:', error));
        //     }, []);

        // AWS Link
        fetch('https://backend.passion-photography.org/all_photos', {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
            })
            .then((response) => response.json())
            .then((data) => {
            setImageUrls(data);
            })
            .catch((error) => console.error('Error fetching pre-signed URLs:', error));
        }, []);

    return (
        <div className='photos-body'>
            <FsLightbox
				toggler={lightboxController.toggler}
				// sources={imageUrlArray}
                sources={imageUrls.map((imageUrl) => imageUrl.url)}
                slide={lightboxController.slide}
                type="image"
			/>
            <div className='photos-container'>
                <div className='photos-heading' style={{paddingBottom: "25px"}}>
                    <h1>All Photos</h1>
                    <hr className="photos-hr"/>
                </div>
                <h3 style={{paddingBottom: "40px"}}>Want to check out specific trips ?</h3>
                <div className="holiday-container" style={{paddingBottom: "50px"}}>
                    <div className="holiday-box" style={{ backgroundImage: "url('/photos/europe.jpg')"}} onClick={() => handleNavigation("/europe_photos")}>
                        <span>Europe</span>
                    </div>
                    <div className="holiday-box" style={{ backgroundImage: "url('/photos/tasmania.jpg')"}} onClick={() => handleNavigation("/tasmania_photos")}>
                        <span>Tasmania</span>
                    </div>
                    <div className="holiday-box" style={{ backgroundImage: "url('/photos/indonesia.jpg')"}} onClick={() => handleNavigation("/indonesia_photos")}>
                        <span>Indonesia</span>
                    </div>

                    <button className="galleries-button" onClick={() => {navigate('/Photos')}}>Other Trips</button>
                </div>
                <div className="gallery">
                {imageUrls.map((imageUrl, index) => (
                    <img key={imageUrl.key} src={imageUrl.url} alt={imageUrl.key} onClick={() => {openLightboxOnSlide(index + 1)}}/>
                ))}
                </div>
            </div>
        </div>
    )
}