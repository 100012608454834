
export const sliderData = [
    {
        id: "first",
        image: "/photos/tasmania.jpg",
        heading: "Cape Raoul Track",
        desc: "One of Our Favourites From Tasmania."
    },
    {
        id: "second",
        image: "/photos/indonesia.jpg",
        heading: "Snorkelling in Lovina",
        desc: "One of Our Favourites From Indonesia."
    },
    {
        id: "third",
        image: "/photos/rome.jpg",
        heading: "Exploring Rome, Italy",
        desc: "One of Our Favourites From Europe."
    }
]