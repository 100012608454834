//Import Neccessary Modules
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FsLightbox from "fslightbox-react";

//Import CSS
import '../css/Photos.css';

export default function Photos() {
    const [imageUrls, setImageUrls] = useState([]);
    const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1
	});
    const token = localStorage.getItem("token");

    function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number
		});
	};

    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    useEffect(() => {
        window.scrollTo(0,0);

        //Localhost Link
        // fetch('http://localhost:4000/aws/all', {
        //     method: "GET",
        //     headers: {
        //         "Content-Type": "application/json",
        //         "Authorization": `Bearer ${token}`
        //     }
        // })

        //AWS Link
        // fetch('https://backend.passion-photography.org/aws/all', {
        //     method: "GET",
        //     headers: {
        //         "Content-Type": "application/json",
        //         "Authorization": `Bearer ${token}`
        //     }
        //     })
        //     .then((response) => response.json())
        //     .then((data) => {
        //     setImageUrls(data);
        //     })
        //     .catch((error) => console.error('Error fetching pre-signed URLs:', error));
        // }, []);
        } )

    return (
        <div className='photos-body'>
            <div className='photos-container'>
                <div className='photos-heading'>
                    <h1>Photo Gallery</h1>
                    <hr className="photos-hr"/>
                    <br />
                    <p>At the moment we only have a small selection of film photos for you to check out. However, come back in the near future to see some new pics !</p>
                </div>
                <div className="photo-holiday-container">
                    <div className="photo-holiday-box" style={{ backgroundImage: "url('/photos/europe.jpg')", width: "350px", height: "450px" }} onClick={() => handleNavigation("/europe_photos")}>
                        <span>Europe</span>
                    </div>
                    <div className="photo-holiday-box" style={{ backgroundImage: "url('/photos/tasmania.jpg')", width: "350px", height: "450px" }} onClick={() => handleNavigation("/tasmania_photos")}>
                        <span>Tasmania</span>
                    </div>
                    <div className="photo-holiday-box" style={{ backgroundImage: "url('/photos/indonesia.jpg')", width: "350px", height: "450px" }} onClick={() => handleNavigation("/indonesia_photos")}>
                        <span>Indonesia</span>
                    </div>
                    <div className="photo-holiday-box" style={{ backgroundImage: "url('/photos/all.jpg')", width: "350px", height: "450px" }} onClick={() => handleNavigation("/all_photos")}>
                        <span>All</span>
                    </div>
                </div>
            </div>
        </div>
    )
}